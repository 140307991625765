<template>
    <a href="javascript:0;"
        data-bs-toggle="popover"
        :title="getTitleText"
        data-bs-trigger="hover"
        data-bs-placement="top"
        data-bs-html="true"
        :data-bs-content="getServiceText"
        v-if="serviceid==35445 || serviceid == 20152 || serviceid == 20153 || serviceid == 20154 || serviceid == 47694">
        <i class="fa-sharp fa-light fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'service-help',
    	props: {
    		serviceid: {
    			type: [Number, String]
    		},
    		servicename: String
    	},
    	mounted: function() {
    		$('[data-bs-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			return this.servicename;
    		},
    		getServiceText: function() {

    			var serviceText = '<div class="row">';



    			if (this.serviceid) {
    				if (this.serviceid == '35445') {
    					serviceText += '<div class="col-md-6">';
    					serviceText += '<ul>';
    					serviceText += '<li>';
    					serviceText += "6 Geschirrspültabs";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "3 Mülltüten groß";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "3 Mülltüten klein";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "4 Rollen Toilettenpapier";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1 Küchenrolle";
    					serviceText += '</li>';
    					serviceText += '</ul>';
    					serviceText += '</div>';
    					serviceText += '<div class="col-md-6">';
    					serviceText += '<ul>';
    					serviceText += '<li>';
    					serviceText += "1 Küchenschwamm";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "2 Abwaschlappen Küche";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x 100ml Geschirrspülmittel";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "Flüssigseife";
    					serviceText += '</li>';
    					serviceText += '</ul>';
    					serviceText += '</div">';
    				} else if (this.serviceid == '20152') {
    					serviceText += '<div class="col-md-6">';
    					serviceText += '<ul>';
    					serviceText += '<li>';
    					serviceText += "1x Liter Milch";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Pfund Kaffee";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "10x Filtertüten";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Früchtetee (Beutel)";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Brot 500g";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Glas Marmelade";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Butter";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "6 Eier";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1 Fl. Wasser";
    					serviceText += '</li>';
    					serviceText += '</ul>';
    					serviceText += '</div>';
    				} else if (this.serviceid == '20153') {
    					serviceText += '<div class="col-md-6">';
    					serviceText += '<ul>';
    					serviceText += '<li>';
    					serviceText += "1x Käseaufschnitt";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Salami";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Serranoschinken";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "4x Joghurt";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Tomaten";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Gurke";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "4x Äpfel";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1 Fl. Orangensaft";
    					serviceText += '</li>';
    					serviceText += '</ul>';
    					serviceText += '</div>';
    					serviceText += '<div class="col-md-6">';
    					serviceText += '<strong>ODER unsere Veggie-Alternative</strong>';
    					serviceText += '<ul>';
    					serviceText += '<li>';
    					serviceText += "1x Veggieaufstrich";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "3er Paprika";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Käseaufschnitt";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "4x Joghurt";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Tomaten";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Gurke";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "4x Äpfel";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1 Fl. Orangensaft";
    					serviceText += '</li>';
    					serviceText += '</ul>';
    					serviceText += '</div>';
    				} else if (this.serviceid == '20154') {
    					serviceText += '<div class="col-md-6">';
    					serviceText += '<ul>';
    					serviceText += '<li>';
    					serviceText += "1x Glas Nutella";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1 Fl. Sekt";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Serranoschinken";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "6 Fl. Bier 0,33l";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "2x Antipasti";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "Müsli 500g";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Frischkäse";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Räucherfisch 200g";
    					serviceText += '</li>';
    					serviceText += '</ul>';
    					serviceText += '</div>';

    				} else if (this.serviceid == '47694') {
    					serviceText += '<div class="col-md-12">';
    					serviceText += '<ul>';
    					serviceText += '<li>';
    					serviceText += "1x Bio-Milch";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Bio-Butter";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Roberstdorfer Eier";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Sanddorn-Tee";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Ciabatta Brot 250g";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Sanddorn-Aufstrich";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Sanddornlikör 200ml";
    					serviceText += '</li>';
    					serviceText += '<li>';
    					serviceText += "1x Räucherfisch 200g";
    					serviceText += '</li>';
    					serviceText += '</ul>';
    					serviceText += '</div>';

    				}
    				serviceText += '</>';
    			}

    			return serviceText;
    		}
    	}
    };
</script>